import { useEffect } from 'react'
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { postRequest } from '../../service/RequestAdapter';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Auth, Hub } from 'aws-amplify';
import { SocialIcon } from 'react-social-icons';
import Paper from '@mui/material/Paper';

interface MyStyles {
    paper: string;
    fullWidth: string;
    inProgress: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
        marginTop: theme.spacing(4),
        textAlign: "center",
        maxWidth: theme.breakpoints.values["sm"],
        margin: "auto",
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    fullWidth: {
        width: '100%'
    },
    inProgress: {
        alignSelf: "center"
    }
  })
);

enum Category {
    RequestQRCodes = "Request-QR-Codes",
    ReportIssue = "Report-Issue",
    FeatureRequest = "Feature-Request",
    Question = "Question"
}

const ContactUs: React.FunctionComponent = (props) => {
    const classes = useStyles() as MyStyles;

    const [authState, setAuthState] = useState<String>("Loading");
    const [user, setUser] = useState<any | undefined>();
    const [category, setCategory] = useState(Category.RequestQRCodes);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        /** React.useState is lost after navigating to other pages, so read globally */
        Auth.currentAuthenticatedUser()
        .then(data => {
            setAuthState("SignedIn");
            setUser(data);
        })
        .catch(err => {
            setAuthState("SignedOut");
            setUser(null);
        });

        const authHandler = function(data: any) {
          const event = data.payload.event;
          const user = data.payload.data;
          if (event === "signOut") {
              setAuthState("SignedOut");
              setUser(null);
          } else if(event  === "signIn") {
              setAuthState("SignedIn");
              setUser(user);
          }
        }
        Hub.listen('auth', authHandler);

        return function cleanup() {
            Hub.remove('auth', authHandler);
        }
    }, []);

    const reset = () => {
        setCategory(Category.RequestQRCodes);
        setTitle("");
        setContent("");
        setIsSubmitting(false);
    }

    const handleCategoryChange = (event: SelectChangeEvent<Category>) => {
        setCategory(event.target.value as Category);
    };

    const handleTitleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setTitle(event.target.value as string);
    };

    const handleContentChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setContent(event.target.value as string);
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        
        const data = await postRequest(`contactUs`, {
            category,
            title,
            content
        });
        console.log("Success submitting message!");
        console.log(JSON.stringify(data));

        setShowSuccess(true);
        reset();
    }

    const renderForm = () => {
        // className was not working
        const socialIconStyle = {
            height: 35,
            width: 35,
            marginLeft: 15,
            marginRight: 15
        };
        return (
            <Paper className={classes.paper}>
                <Grid
                    container
                    direction="column"
                    spacing={3}
                    alignItems="center"
                >
                    <Grid item>
                        <Typography variant="h3">Contact Us</Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent="center"
                    >
                        <Grid item>
                            <SocialIcon url="https://www.instagram.com/KandiLoveLLC/" style={socialIconStyle} />
                        </Grid>
                        <Grid item>
                            <SocialIcon url="https://www.facebook.com/KandiLoveLLC" style={socialIconStyle} />
                        </Grid>
                        <Grid item>
                            <SocialIcon url="https://twitter.com/KandiLoveLLC" style={socialIconStyle} />
                        </Grid>
                        <Grid item>
                            <SocialIcon url="https://discord.gg/WanJqen9" style={socialIconStyle} />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">
                            Want to request your own QR codes to start tracking your own Kandi?
                            Have questions, comments or concerns?
                            Use the form below to send us a message, and someone from our staff will reply
                            to the email address you used when signing up for KandiLove.
                        </Typography>
                    </Grid>
                    <Grid item className={classes.fullWidth}>
                        <InputLabel id="category-input-label">What is this about?</InputLabel>
                        <Select
                            labelId="category-input-label"
                            id="category-input"
                            value={category}
                            onChange={handleCategoryChange}
                            className={classes.fullWidth}
                        >
                            <MenuItem value={Category.RequestQRCodes}>Request for QR Codes</MenuItem>
                            <MenuItem value={Category.ReportIssue}>Report an issue</MenuItem>
                            <MenuItem value={Category.FeatureRequest}>Request a feature</MenuItem>
                            <MenuItem value={Category.Question}>General Question?</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item className={classes.fullWidth}>
                        <TextField
                            id="title-input"
                            label="Message Title"
                            className={classes.fullWidth}
                            value={title}
                            onChange={handleTitleChange}
                        />
                    </Grid>
                    <Grid item className={classes.fullWidth}>
                        <TextField 
                            className={classes.fullWidth}
                            multiline
                            rows={8}
                            id="content-input"
                            label="Message"
                            value={content}
                            onChange={handleContentChange}
                        />
                    </Grid>
                    { isSubmitting ? (
                        <Grid item className={classes.inProgress}>
                            <CircularProgress 
                                color="primary" 
                            />
                        </Grid>
                    ) : 
                    (
                        <Grid item className={classes.fullWidth}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={handleSubmit}
                                disabled={!(category && title && content)}
                                fullWidth
                            >
                                Submit!
                            </Button>
                        </Grid>
                    )}
                </Grid>
                <Snackbar open={showSuccess} autoHideDuration={3000} onClose={()=>setShowSuccess(false)}>
                    <MuiAlert onClose={()=>setShowSuccess(false)} severity="success">
                        Message has been submitted!
                    </MuiAlert>
                </Snackbar>
            </Paper>
        );
    }

    switch (authState) {
        case "SignedIn":
            return renderForm();
        case "SignedOut":
            return (
                <Paper className={classes.paper}>
                    <Typography variant="body2">
                        You must first sign in to send us a message.
                    </Typography>
                </Paper>
            );
        default:
            return (
                <Paper className={classes.paper}>
                    <Typography variant="body2">
                        loading...
                    </Typography>
                </Paper>
            )
    }
}

export default ContactUs;