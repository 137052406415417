import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { FirstTimeSigninProps } from './types';
import { SocialIcon } from 'react-social-icons';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const socialIconStyle = {
  height: 35,
  width: 35,
  marginLeft: 15,
  marginRight: 15
};

const socialMediaUsernameContainer = {
  overflow: "hidden", 
  textOverflow: "ellipsis", 
  width: '11rem'
}

const images = [
  {
    label: "Welcome to the Kandi Love Fam!",
    imgPath: process.env.PUBLIC_URL + '/welcome_1.png',
    stepBody: () => {
      return (
        <Typography>
          {"We are glad you are here! Kandi Love is a free Kandi App built for the rave community. "
      + "Here is a quick walkthrough of what you can do with Kandi Love!"}
        </Typography>
      )
    }
  },
  {
    label: 'Register your Kandi',
    imgPath: process.env.PUBLIC_URL + '/welcome_2.png',
    stepBody: () => {
      return (
        <Typography>
          { "Add Kandi you've created to  your collection, attach a Unity Bead, "
     + "and scan/register the QR code on the Unity Bead to start the Kandi's journey."}
        </Typography>
      )
    }
  },
  {
    label: 'Trade and Scan',
    imgPath: process.env.PUBLIC_URL + '/welcome_3.png',
    stepBody: () => {
      return (
        <Typography>
          {  "Trade your kandi, and when your friend scans the QR code on the Unity Bead "
     + "they will be taken to the dedicated Kandi page where they can claim the Kandi and add it "
     + "to their own collection."}
        </Typography>
      )
    }
  },
  {
    label: 'Keep in touch',
    imgPath: process.env.PUBLIC_URL + '/welcome_4.png',
    stepBody: () => {
      return (
        <Typography>
          {  "The history of Kandi ownership is found on the dedicated Kandi page so you "
     + "can find the previous owner, view their profile, and keep in touch."}
        </Typography>
      )
    }
  },
  {
    label: 'Thank you for being here!',
    imgPath: process.env.PUBLIC_URL + '/welcome_5.png',
    stepBody: () => {
      return (
        <Box>
           <Typography>
          { "Stay up to date on everything we do by following us on Instagram!"}
          </Typography>
          <Grid container direction="row" alignItems="center" margin="10px">
            <Grid item>
                <SocialIcon url={"https://www.instagram.com/kandilovellc/"} style={socialIconStyle}/>
            </Grid>
            <Grid item>
                <div style={socialMediaUsernameContainer}> 
                    <Typography noWrap variant="body2">
                        <Link href="https://www.instagram.com/kandilovellc/" target="_blank">
                          {"@KandiLoveLLC"}
                        </Link>
                    </Typography>
                </div>
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center" margin="10px">
            <Grid item>
              <IconButton
              style={socialIconStyle}
              href="https://kandilove.com/collections/unity-beads"
              target="_blank"
            >
              <Avatar src={process.env.PUBLIC_URL + '/unity_bead_icon.png'}/>
            </IconButton>
            </Grid>
            <Grid item>
                <div style={socialMediaUsernameContainer}> 
                    <Typography noWrap variant="body2">
                        <Link href="https://kandilove.com/collections/unity-beads" target="_blank">
                          {"Shop Unity Beads"}
                        </Link>
                    </Typography>
                </div>
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center" margin="10px">
            <Grid item>
              <IconButton
              style={socialIconStyle}
              href="https://blog.kandilove.com/2024/07/03/how-to-make-a-unity-bead-at-home-for-free/"
              target="_blank"
            >
              <Avatar src={process.env.PUBLIC_URL + '/unity_bead_icon.png'}/>
            </IconButton>
            </Grid>
            <Grid item>
                <div style={socialMediaUsernameContainer}> 
                    <Typography noWrap variant="body2">
                        <Link href="https://blog.kandilove.com/2024/07/03/how-to-make-a-unity-bead-at-home-for-free/" target="_blank">
                          {"FREE DIY Unity Beads"}
                        </Link>
                    </Typography>
                </div>
            </Grid>
          </Grid>
        </Box>
       
      )
    }
  }
];

  const FirstTimeSigninModal: React.FunctionComponent<FirstTimeSigninProps> = (props) => {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;
  
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStepChange = (step: number) => {
      setActiveStep(step);
    };

    const closeText = () => {
      if (activeStep === maxSteps - 1) {
        return "Close"
      } else {
        return "Skip"
      }
    }

    
  
    return (
      <Dialog
        open={props.open}
      >
        <DialogContent>
          <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
            <AutoPlaySwipeableViews
              interval={10000}
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
              autoplay={!(activeStep === maxSteps - 1)}
            >
              {images.map((step, index) => (
                <div key={step.label}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Box
                      component="img"
                      sx={{
                        display: 'block',
                        maxWidth: 400,
                        overflow: 'hidden',
                        width: '100%',
                      }}
                      src={step.imgPath}
                      alt={step.label}
                    />
                  ) : null}
                </div>
              ))}
            </AutoPlaySwipeableViews>
            <Paper
              square
              elevation={0}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                pl: 2,
                bgcolor: '#000000',
                minHeight: '225px'
              }}
            >
              <Typography 
                variant='h5'
                color={theme.palette.primary.main}
              >
                {images[activeStep].label}
              </Typography>
              {images[activeStep].stepBody()}
            </Paper>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
            <Button 
                color="primary" 
                onClick={props.onClose}
            >
                {closeText()}
            </Button>
        </DialogActions>
      </Dialog>
    );
  }
  
  export default FirstTimeSigninModal;