import { putRequest } from "../../service/RequestAdapter";
import { AddTagProps } from "./types";
import QRScanner from '../qrscanner';
import './styles.scss'
import { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

enum SetKandiTagResult {
    InProgress,
    Success,
    TagTaken,
    Error,
    NotSet
}

const AddTag: React.FunctionComponent<AddTagProps> = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [setKandiTagResult, setSetKandiTagResult] = useState(SetKandiTagResult.NotSet);

    const callSetKandiTag = async function(tagId: string) {
        console.log("callSetKandiTag called, tag =" + tagId);
        try {
            const data = await putRequest(`tags/${tagId}`, { kandiId : props.userKandi.kandiId });
            console.log ("Success setting Tag!");
            console.log (data);
            setSetKandiTagResult(SetKandiTagResult.Success);
            if (props.onSetTag) {
                props.onSetTag(true);
            }
        } catch (err: any) {
            if (err?.response?.data?.name === "TagTaken") {
                console.log("Tag is already in use, please pick a different tag");
                setSetKandiTagResult(SetKandiTagResult.TagTaken);
            } else {
                console.error(err);
                setSetKandiTagResult(SetKandiTagResult.Error);
            }
            if (props.onSetTag) {
                props.onSetTag(false);
            }
        }
    }
        
    const callSetKandiTagFromURL = async function(tagURL: any) {
        setSetKandiTagResult(SetKandiTagResult.InProgress);
        const tagId = tagURL.text.substring(tagURL.text.lastIndexOf('/') + 1);
        callSetKandiTag(tagId);
    }

    useEffect(() => {
        if (props.tag && props.open) {
            callSetKandiTagFromURL(props.tag);
        }
    }, [props.tag, props.open]);

    const clearAndClose = function() {
        setSetKandiTagResult(SetKandiTagResult.NotSet);
        props.onClose();
    }

    const tagStatusComp = function (status: string) {
        return (
            <Typography variant="body1">
                {status}
            </Typography>
        )
    }

    let modalContent = null;
    switch(setKandiTagResult) {
        case SetKandiTagResult.NotSet:
            modalContent = <QRScanner onScan={(data) => callSetKandiTagFromURL(data)} />
            break;
        case SetKandiTagResult.InProgress:
            modalContent = tagStatusComp("Adding tag to kandi, please wait...");
            break;
        case SetKandiTagResult.Success:
            modalContent = tagStatusComp("Successfully added tag to kandi!");
            break;
        case SetKandiTagResult.TagTaken:
            modalContent = tagStatusComp("Tag is already associated with another Kandi. Please try again with a different tag.");
            break;
        case SetKandiTagResult.Error:
            modalContent = tagStatusComp("Failed adding tag to kandi, please try again.");
            break;
    }

    let retry = null;
    if ([SetKandiTagResult.Error, SetKandiTagResult.TagTaken].includes(setKandiTagResult)) {
        retry = (
            <Button 
                color="primary" 
                onClick={()=>{setSetKandiTagResult(SetKandiTagResult.NotSet)}}
            >
                Retry
            </Button>
        )
    }

    if (props.tag) {
        return <div></div>
    } else {
        return (
            <Dialog 
                open={props.open} 
                onClose={props.onClose} 
                aria-labelledby="dialog-title"
                fullScreen={fullScreen}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="dialog-title">Scan QR Code</DialogTitle>
                <DialogContent>
                    { modalContent }
                </DialogContent>
                <DialogActions>
                    { retry }
                    <Button 
                        color="primary" 
                        onClick={clearAndClose} 
                        disabled={setKandiTagResult === SetKandiTagResult.InProgress}
                    >
                        {setKandiTagResult === SetKandiTagResult.Success ? "Close" : "Cancel"}
                    </Button>
                </DialogActions>
            </Dialog>
            
        );
    }
};

export default AddTag;